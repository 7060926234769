@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Roboto+Mono&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  background: url("/assets/images/amazing-shot.jpg") 0 0;
  background-attachment: fixed;
  background-size: cover;
  font-size: 1.1rem;
}

code {
  font-family: 'Roboto Mono', monospace;
}

.navbar-light .navbar-brand {
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  opacity: 0.9;
}

.navbar-light .navbar-brand:hover {
  color: #fff;
  opacity: 1;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  margin-left: 2rem;
  border-bottom: 1px solid transparent;
  opacity: 0.5;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
  // text-decoration: underline;
  border-bottom: 1px solid #fff;
  opacity: 0.6;
  transition: all ease-in-out .2s;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
  color: #fff;
  font-weight: bold;
  opacity: 1;
}

img.logo {
  max-width: 160px;
}

.banner {
  min-height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.banner .line1 {
  font-weight: 300;
  font-size: 3vw;
}

.banner .line2 {
  font-weight: 300;
  font-size: 5vw;
}

.banner .line2 .bold {
  font-weight: 700;
}

.banner .line3 {
  font-weight: 300;
  font-size: 3vw;
}

.banner .social-icons {
  padding-top: 2rem;
}

.banner .social-icons a {
  color: #fff;
  text-decoration: none;
  opacity: 0.5;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}

.banner .social-icons a:hover {
  color: #fff;
  text-decoration: none;
  opacity: 1;
}

.banner .social-icons a svg {
  width: 50px;
  height: 50px;
}

.content {
  background: #fff;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.profile-pic {
  border-radius: 50%;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.post-cards {
  align-items: stretch !important;
}

.post-card {
  color: #000;
  text-decoration: none;
  height: 100% !important;
  transition: all ease-in-out .2s;
}

.post-card:hover {
  color: #000;
  text-decoration: none;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
}

.post-card h2 {
  font-size: 1.6rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.post-card p {
  margin: 0.6rem 0 0 0;
}

.post h1 {
  margin-bottom: 3rem;
  font-weight: 700;
}

.post h2 {
  margin-bottom: 1.5rem;
}

.post h3 {
  margin-bottom: 1rem;
}

.highlighter-rouge {
  padding: 1.6rem 1.2rem 1rem 1.2rem;
  background: #f0f0f0;
  margin-bottom: 1.5rem;
}

// C#
.language-c\# .c1 {
  color: green;
}

.language-c\# .k, .language-c\# .kt {
  color: blue;
}

.language-c\# .s {
  color: brown;
}

// HTML
.language-html .na, .language-html .nl {
  color: red;
}

.language-html .nt, .language-html .nc {
  color: brown;
}

.language-html .no, .language-html .m {
  color: blue;
}

.language-html .c {
  color: green;
}

// XML
.language-xml .nt {
  color: blue;
}

.language-xml .s {
  color: purple;
}

// SQL
.language-sql .k {
  color: blue;
}

.language-sql .mi {
  color: red;
}

// JSON
.language-json .mi, .language-json .s2 {
  font-weight: bold;
}

footer {
  background: rgba(0,0,0,0.6);
  padding-top: 3rem;
  padding-bottom: 2rem;
  color: #ccc;
  text-align: center;
}

@media all and (max-width:991px) {

  body {
    font-size: 1.4rem;
  }

  .banner .line1 {
    font-size: 4vw;
  }

  .banner .line2 {
    font-size: 6vw;
  }

  .banner .line3 {
    font-size: 4vw;
  }

  .profile-pic-wrap {
    text-align: center;
  }

  .profile-pic {
    margin-bottom: 2rem;
  }

  .navbar-light .navbar-brand {
    font-size: 1.8rem;
  }

  .navbar-light .navbar-toggler {
    background-color: #fff;
    color: #fff;
    border-color: #fff;
  }

  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
  }

  .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .banner .social-icons a svg {
    width: 60px;
    height: 60px;
  }
}

@media all and (max-width:767px) {

  .navbar-light .navbar-nav .nav-link {
    opacity: 1;
  }

  .banner .line1 {
    font-size: 1.5em;
  }

  .banner .line2 {
    font-size: 1.8em;
  }

  .banner .line3 {
    font-size: 1.5em;
  }
}
